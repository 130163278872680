import Alert from 'bootstrap.native/src/components/alert-native'
import Collapse from 'bootstrap.native/src/components/collapse-native'
//import Dropdown from 'bootstrap.native/src/components/dropdown-native'

function initFades(form) {
  form.querySelectorAll('.fade').forEach((div) => {
    setTimeout(() => div.classList.remove('show'), 3000);
    setTimeout(() => div.remove(), 3200);
  })
}
function initBackTop() {
  const back_top = document.getElementById("back-top");
  let shown = false;

  back_top.addEventListener("click", (e) => {
    e.preventDefault();
    // or requestAnimationFrame with 800ms
    document.scrollingElement.scrollTo(0, 0);
  });
  window.addEventListener("scroll", onScroll);
  onScroll(); // initialize on reload

  function onScroll() {
    if (document.scrollingElement.scrollTop > 100) {
      if (!shown) {
        back_top.classList.add("show");
        shown = true;
      }
    } else {
      if (shown) {
        back_top.classList.remove("show");
        shown = false;
      }
    }
  }
}

function initSubscribeForm() {
  document.querySelectorAll('.js-newsletter-signup form').forEach(form => {
    form.addEventListener('submit', onNewsletterSubmit);
    initFades(form);
  });
}

async function onNewsletterSubmit(event)
{
  const form = this;
  event.preventDefault();
  const r = await fetch(form.dataset['action'], {method:'post', body: new FormData(form)});
  if(r.ok) {
    const data = await r.json();
    if (data.html) form.outerHTML = data.html;
    initSubscribeForm();  // for server-side displayed errors
  } else {
    // Same effect as backend.
    form.insertAdjacentHTML( 'beforeend', "<div class='invalid-tooltip fade show'>Sorry er ging iets mis...</div>")
    initFades(form);
  }
}

function initComponents() {
  [Alert, Collapse].forEach(function(c) {
    document.querySelectorAll(c.selector).forEach(c.init);
  });

  initSubscribeForm();
  initBackTop();
}

// bulk initialize all components
document.body ? initComponents() :
addEventListener( 'DOMContentLoaded', initComponents, { once: true })
